<template>
  <div class="content">
    <siteHeader />
    <div class="container-fluid mt--9">
      <div>
        <card class="no-border-card" body-classes="pb-1" footer-classes="pb-2">
          <template v-slot:header>
            <h3>Member</h3>
          </template>
          <div v-if="isLoading">
            <base-alert type="danger" dismissible>
              <span class="alert-inner--text">
                Record Deleted Successfully</span
              >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </base-alert>
          </div>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div>
                <base-button
                  type="primary"
                  class="mb-4"
                  size="sm"
                  @click="$router.push('/member/member/undefined')"
                  >Create</base-button
                >
                <base-button
                  type="primary"
                  class="mb-4"
                  size="sm"
                  @click="printtable()"
                >
                  <i class="fa fa-print mr-2" size="5x"></i>
                  Export</base-button
                >
              </div>

              <div>
                <el-input
                  type="search"
                  class="mb-3"
                  clearable
                  prefix-icon="el-icon-search"
                  style="width: 200px"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                >
                </el-input>
              </div>
            </div>
            <el-table
              @row-dblclick="dbSelected"
              class="table-responsive table"
              :data="queriedData"
              header-row-class-name="thead-light"
            >
              <el-table-column
                label="First Name"
                sortable
                min-width="70px"
                prop="name"
              >
                <template v-slot="{ row }">
                  <div>
                    {{ row.name }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Last Name"
                sortable
                min-width="70px"
                prop="lastname"
              >
                <template v-slot="{ row }">
                  <div>
                    {{ row.lastname }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="MemberGroup"
                sortable
                min-width="70px"
                prop="member_group_data"
              >
                <template v-slot="{ row }">
                  <div v-for="item in memberGroupData" :key="item.id">
                    <span v-if="item.id === row.member_group">
                      {{ (row.member_group_data = item.members_group_name) }}
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Active"
                sortable
                min-width="70px"
                prop="is_active"
              >
                <template v-slot="{ row }">
                  <div>
                    <div v-if="row.is_active == '1'">Active</div>
                    <div v-if="row.is_active == '2'">Inactive</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="100px"
                align="right"
                label="Actions"
                sortable
              >
                <template v-slot="{ row }">
                  <div class="d-flex">
                    <i class="fas fa-trash-alt" @click="ondelete(row)"></i>
                    &nbsp;
                    <i class="fas fa-edit" @click="onEdit(row)"></i>
                    &nbsp;
                    <i class="far fa-eye" @click="openModel(row)"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- <el-table
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @row-dblclick="dbSelected"
            >
              <el-table-column
                min-width="80px"
                align="right"
                label="First Name"
                sortable
              >
                <template v-slot:default="props">
                  <div class="d-flex">
                    {{ props.row.name.split(" ")[0] }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                min-width="80px"
                align="right"
                label="Last Name"
                sortable
              >
                <template v-slot:default="props">
                  <div class="d-flex">
                    <div>
                      <span> {{ props.row.name.split(" ")[1] }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
                sortable
              >
              </el-table-column>

              <el-table-column
                min-width="100px"
                align="right"
                label="Membership"
                sortable
              >
                <template v-slot:default="props">
                  <div class="d-flex">
                    <div v-for="item in membershipData" :key="item.id">
                      <span v-if="item.id === props.row.membership">
                        {{ (props.row.membership_data = item.membership_name) }}
                      </span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="100px"
                align="right"
                label="MemberGroup"
                sortable
              >
                <template v-slot:default="props">
                  <div class="d-flex">
                    <div v-for="item in memberGroupData" :key="item.id">
                      <span v-if="item.id === props.row.member_group">
                        {{
                          (props.row.member_group_data =
                            item.members_group_name)
                        }}
                      </span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="100px"
                align="right"
                label="Active"
                sortable
              >
                <template v-slot:default="props">
                  <div class="d-flex">
                    <div v-if="props.row.is_active == '1'">Active</div>
                    <div v-if="props.row.is_active == '2'">Inactive</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                min-width="100px"
                align="right"
                label="Actions"
                sortable
              >
                <template v-slot:default="props">
                  <div class="d-flex">
                    <i
                      class="fas fa-trash-alt"
                      @click="ondelete(props.row)"
                    ></i>
                    &nbsp;
                    <i class="fas fa-edit" @click="onEdit(props.row)"></i>
                    &nbsp;
                    <i class="far fa-eye" @click="openModel(props.row)"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table> -->
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
              <modal v-model:show="modals.modal3">
                <template v-slot:header>
                  <h6 class="modal-title" id="modal-title-default">
                    Member Details
                  </h6>
                </template>

                <div class="row">
                  <div class="col-6">First Name:</div>
                  <div class="col-6">{{ this.mode_active.name }}</div>
                </div>
                <div class="row">
                  <div class="col-6">Last Name:</div>
                  <div class="col-6">{{ this.mode_active.lastname }}</div>
                </div>
                <div class="row">
                  <div class="col-md-6">Membership:</div>
                  <div class="col-md-6">
                    <div v-for="item in membershipData" :key="item.id">
                      <span v-if="item.id === this.mode_active.membership">
                        {{ item.membership_name }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">Members group:</div>
                  <div class="col-md-6">
                    <div v-for="item in memberGroupData" :key="item.id">
                      <span v-if="item.id === this.mode_active.member_group">
                        {{ item.members_group_name }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">Phone:</div>
                  <div class="col-md-6">
                    {{ this.mode_active.phone }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">Email:</div>
                  <div class="col-md-6">
                    {{ this.mode_active.email }}
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">Note:</div>
                  <div class="col-md-6">
                    {{ this.mode_active.note }}
                  </div>
                </div>

                <template v-slot:footer>
                  <base-button
                    type="link"
                    class="ml-auto"
                    @click="modals.modal3 = false"
                    >Close
                  </base-button>
                </template>
              </modal>
              <modal
                v-model:show="showEditModal"
                modal-classes="modal-secondary"
              >
                <div style="text-align: center">
                  <h3>Are you sure you want to delete this item?</h3>

                  <base-button type="danger" @click="deleteEvent"
                    >Yes</base-button
                  >
                  <base-button type="success" @click="showEditModal = false"
                    >No</base-button
                  >
                </div>
              </modal>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const memberRepository = RepositoryFactory.get("member");
const membergroupRepository = RepositoryFactory.get("membergroup");
const memberShipRepository = RepositoryFactory.get("membership");
import siteHeader from "../../global/header.vue";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
export default {
  components: {
    siteHeader,
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  name: "projects-table",
  computed: {
    pagedData() {
      return this.memberData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.memberData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (
            rowValue.includes &&
            rowValue.toLowerCase().includes(this.searchQuery)
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.memberData.length;
    },
  },
  data() {
    return {
      showEditModal: false,
      searchQuery: "",
      isLoading: false,
      tableData: [],
      memberData: [],
      membershipData: [],
      memberGroupData: [],
      modals: {
        modal3: false,
      },
      mode_active: {},
      model: {
        name: "",
        address: "",
        membership: "",
        member_group: "",
        Subscriptions: "",
        subscription_Charge_type: "",
        note: "",
        is_active: "",
        phone: "",
        email: "",
        is_delete: "",
      },
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      propsToSearch: ["name", "full_name", "lastname", "member_group_data"],
      // tableColumns: [
      //   {
      //     prop: "email",
      //     label: "Email",
      //     minWidth: 100,
      //   },
      // ],

      fuseSearch: null,
      searchedData: [],
    };
  },
  methods: {
    printtable() {
      window.open(
        "https://shulsoftware.adass.com.au/api/index.php/Member/export",
        "_blank"
      );
    },
    openModel(row) {
      this.mode_active = row;

      this.modals.modal3 = true;
    },
    async deleteEvent() {
      this.model.is_delete = "Yes";
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await memberRepository.delete(data);
      await this.getList();
      if (response.data.status == 101) {
        this.isLoading = true;
        this.showEditModal = false;
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
    },
    ondelete(row) {
      this.model = row;
      this.showEditModal = true;
    },
    dbSelected(row) {
      this.$router.push({ name: "createmember", params: { data: row.id } });
    },
    onEdit(row) {
      row.edit = 1;
      this.$router.push({ name: "createmember", params: { data: row.id } });
    },

    async getList() {
      this.tableData = await memberRepository.getMember();
      this.memberData = this.tableData.data;
    },
    async getMembershipList() {
      this.tableData = await memberShipRepository.getMembership();
      this.membershipData = this.tableData.data;
    },
    async getMemberGroupList() {
      this.tableData = await membergroupRepository.getMember();
      this.memberGroupData = this.tableData.data;
    },
  },
  async mounted() {
    await this.getMemberGroupList();
    await this.getList();
    this.getMembershipList();
  },
};
</script>
<style></style>
